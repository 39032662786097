/**
 * serializers.js
 *
 * Provides functions for serialization/deserialization
 * of objects between APIv2 and the admin-portal app.
 */
import { nanoid } from 'nanoid'

import { MissingRequiredValueError } from '@/utils/errors'
import { createBlankAddress, isEmptyAddress } from '@/utils/rooof'
import {
  dateToString,
  filterEmptyValues,
  isDate,
  isEmptyString,
  isNull,
  isUndefined,
  isObject,
  isString
} from '@/utils'

import { marked } from 'marked'
import TurndownService from 'turndown'
import { gfm } from 'turndown-plugin-gfm'

// https://github.com/mixmark-io/turndown
// Service to convert HTML to Markdown
const turndownService = new TurndownService({
  headingStyle: 'atx',
  codeBlockStyle: 'fenced'
}).use(gfm)

const serializers = {
  /**
   * De-serialize company object so it can be used in the view.
   *
   * @param {Object} company
   * @returns {Object}
   */
  deserializeCompany (company) {
    if (!company) {
      throw new MissingRequiredValueError('company')
    }
    const companyCopy = JSON.parse(JSON.stringify(company))

    // ElementUI's InputNumber component defaults to 0 when the value is null
    // so we have to convert this to undefined
    if (isNull(companyCopy.managed_properties)) {
      companyCopy.managed_properties = undefined
    }

    // Convert JSONField to string
    if (isObject(companyCopy.extra)) {
      companyCopy.extra = JSON.stringify(companyCopy.extra)
    }

    // Convert Markdown to HTML
    if (companyCopy.notes) {
      companyCopy.notes = marked.parse(companyCopy.notes, { breaks: true })
    }
    if (companyCopy.service_notes) {
      companyCopy.service_notes = marked.parse(companyCopy.service_notes, { breaks: true })
    }

    if (!companyCopy.active_campaign_deals) {
      companyCopy.active_campaign_deals = []
    }

    return companyCopy
  },
  /**
   * Serialize company object in preparation for request payload.
   *
   * @param {Object} company
   * @returns {Object}
   */
  serializeCompany (company) {
    if (!company) {
      throw new MissingRequiredValueError('company')
    }
    const companyCopy = JSON.parse(JSON.stringify(company))

    if (isDate(companyCopy.start_date)) {
      companyCopy.start_date = dateToString(companyCopy.start_date)
    }
    if (isDate(companyCopy.end_date)) {
      companyCopy.end_date = dateToString(companyCopy.end_date)
    }

    // Convert empty strings to null
    if (companyCopy.hasOwnProperty('managed_properties') && (isEmptyString(companyCopy.managed_properties) || isUndefined(companyCopy.managed_properties))) {
      companyCopy.managed_properties = null
    }
    if (companyCopy.hasOwnProperty('billing_frequency') && isEmptyString(companyCopy.billing_frequency)) {
      companyCopy.billing_frequency = null
    }
    if (companyCopy.hasOwnProperty('short_url_domain') && isEmptyString(companyCopy.short_url_domain)) {
      companyCopy.short_url_domain = null
    }
    if (companyCopy.hasOwnProperty('default_emailhandler_domain') && isEmptyString(companyCopy.default_emailhandler_domain)) {
      companyCopy.default_emailhandler_domain = null
    }

    // Filter empty values from lists
    if (companyCopy.hasOwnProperty('email_domains')) {
      companyCopy.email_domains = filterEmptyValues(companyCopy.email_domains)
    }
    if (companyCopy.hasOwnProperty('additional_emailhandler_domains')) {
      companyCopy.additional_emailhandler_domains = filterEmptyValues(companyCopy.additional_emailhandler_domains)
    }
    if (companyCopy.hasOwnProperty('disclaimer')) {
      companyCopy.disclaimer = filterEmptyValues(companyCopy.disclaimer)
    }
    if (companyCopy.hasOwnProperty('active_campaign_deals')) {
      companyCopy.active_campaign_deals = filterEmptyValues(companyCopy.active_campaign_deals)
    }

    // Convert `extra` back into JSON
    if (companyCopy.hasOwnProperty('extra') && isString(companyCopy.extra)) {
      if (isEmptyString(companyCopy.extra)) {
        companyCopy.extra = {}
      } else {
        try {
          companyCopy.extra = JSON.parse(companyCopy.extra)
        } catch (err) {
          throw new Error('serializeCompany received invalid JSON data for property: `extra`')
        }
      }
    }

    // Convert HTML to markdown
    if (companyCopy.notes) {
      companyCopy.notes = turndownService.turndown(companyCopy.notes)
    }
    if (companyCopy.service_notes) {
      companyCopy.service_notes = turndownService.turndown(companyCopy.service_notes)
    }

    return companyCopy
  },
  /**
   * De-serialize property object so it can be used in the view.
   *
   * @param {Object} property
   * @returns {Object}
   */
  deserializeProperty (property) {
    if (!property) {
      throw new MissingRequiredValueError('property')
    }
    const propertyCopy = JSON.parse(JSON.stringify(property))

    // Convert null addresses to empty address objects
    if (!propertyCopy.billing_address) {
      propertyCopy.billing_address = createBlankAddress()
    }
    if (!propertyCopy.property_address) {
      propertyCopy.property_address = createBlankAddress()
    }

    // Add a unique identifier for each subscription
    if (propertyCopy.hasOwnProperty('product_subscriptions')) {
      for (const subscription of propertyCopy.product_subscriptions) {
        subscription.uid = nanoid()
      }
    }
    if (propertyCopy.hasOwnProperty('feature_subscriptions')) {
      for (const subscription of propertyCopy.feature_subscriptions) {
        subscription.uid = nanoid()
      }
    }

    // Zoho ids are unique, so we need to turn nulls into empty strings
    if (isNull(propertyCopy.zoho_customer_id)) {
      propertyCopy.zoho_customer_id = ''
    }

    // ElementUI's InputNumber component defaults to 0 when the value is null
    // so we have to convert this to undefined
    if (isNull(propertyCopy.unit_count)) {
      propertyCopy.unit_count = undefined
    }

    // Convert JSON fields into string
    propertyCopy.extra = JSON.stringify(propertyCopy.extra)
    propertyCopy.config_override = JSON.stringify(propertyCopy.config_override)

    // Convert Markdown to HTML
    if (propertyCopy.notes) {
      propertyCopy.notes = marked.parse(propertyCopy.notes, { breaks: true })
    }
    if (propertyCopy.service_notes) {
      propertyCopy.service_notes = marked.parse(propertyCopy.service_notes, { breaks: true })
    }

    return propertyCopy
  },
  /**
   * Serialize property object in preparation for request payload.
   *
   * @param {Object} property
   * @returns {Object}
   */
  serializeProperty (property) {
    if (!property) {
      throw new MissingRequiredValueError('property')
    }
    const propertyCopy = JSON.parse(JSON.stringify(property))

    // The API doesn't allow empty address fields, so we need to nullify them
    if (propertyCopy.hasOwnProperty('billing_address') && !isNull(propertyCopy.billing_address)) {
      if (isEmptyAddress(propertyCopy.billing_address)) {
        propertyCopy.billing_address = null
      } else {
        if (isEmptyString(propertyCopy.billing_address.latitude)) {
          propertyCopy.billing_address.latitude = null
        }
        if (isEmptyString(propertyCopy.billing_address.longitude)) {
          propertyCopy.billing_address.longitude = null
        }
      }
    }
    if (propertyCopy.hasOwnProperty('property_address') && !isNull(propertyCopy.property_address)) {
      if (isEmptyAddress(propertyCopy.property_address)) {
        propertyCopy.property_address = null
      } else {
        if (isEmptyString(propertyCopy.property_address.latitude)) {
          propertyCopy.property_address.latitude = null
        }
        if (isEmptyString(propertyCopy.property_address.longitude)) {
          propertyCopy.property_address.longitude = null
        }
      }
    }

    // Zoho ids are unique, so they need to be null rather than empty string
    if (isEmptyString(propertyCopy.zoho_customer_id)) {
      propertyCopy.zoho_customer_id = null
    }

    // DecimalFields do not permit the empty string, we need to use null instead
    if (propertyCopy.hasOwnProperty('product_subscriptions')) {
      for (const subscription of propertyCopy.product_subscriptions) {
        if (isEmptyString(subscription.discount)) {
          subscription.discount = null
        }
      }
    }
    if (propertyCopy.hasOwnProperty('feature_subscriptions')) {
      for (const subscription of propertyCopy.feature_subscriptions) {
        if (isEmptyString(subscription.discount)) {
          subscription.discount = null
        }
      }
    }

    // Convert null values to empty strings
    if (isNull(propertyCopy.type)) {
      propertyCopy.type = ''
    }

    // Filter empty values from lists
    if (propertyCopy.hasOwnProperty('floor_plan_urls')) {
      propertyCopy.floor_plan_urls = filterEmptyValues(propertyCopy.floor_plan_urls)
    }
    if (propertyCopy.hasOwnProperty('email_agency_decisionmaker')) {
      propertyCopy.email_agency_decisionmaker = filterEmptyValues(propertyCopy.email_agency_decisionmaker)
    }

    if (isUndefined(propertyCopy.unit_count)) {
      propertyCopy.unit_count = null
    }

    // Convert `extra` back into JSON
    if (propertyCopy.hasOwnProperty('extra') && isString(propertyCopy.extra)) {
      if (isEmptyString(propertyCopy.extra)) {
        propertyCopy.extra = {}
      } else {
        try {
          propertyCopy.extra = JSON.parse(propertyCopy.extra)
        } catch (err) {
          throw new Error('serializeProperty received invalid JSON data for property: `extra`')
        }
      }
    }

    // Convert `config_override` back into JSON
    if (propertyCopy.hasOwnProperty('config_override') && isString(propertyCopy.config_override)) {
      if (isEmptyString(propertyCopy.config_override)) {
        propertyCopy.config_override = {}
      } else {
        try {
          propertyCopy.config_override = JSON.parse(propertyCopy.config_override)
        } catch (err) {
          throw new Error('serializeProperty received invalid JSON data for property: `config_override`')
        }
      }
    }

    // Convert HTML to markdown
    if (propertyCopy.notes) {
      propertyCopy.notes = turndownService.turndown(propertyCopy.notes)
    }
    if (propertyCopy.service_notes) {
      propertyCopy.service_notes = turndownService.turndown(propertyCopy.service_notes)
    }

    return propertyCopy
  },
  /**
   * De-serialize agreement data so it can be used in the view.
   *
   * @param {Object} agreement
   * @returns {Object}
   */
  deserializeAgreement (agreement) {
    return {
      id: agreement.id,
      url: agreement.file,
      name: agreement.file.split('/').slice(-1)[0],
      mimeType: agreement.mime_type
    }
  }
}

export default serializers
