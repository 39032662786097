<template>
  <div v-loading="loading">
    <el-row class="section">
      <h2>
        Poster Summary
      </h2>
      <data-table id="summary" :data="posters">
        <el-table-column
          label="Poster"
          prop="poster"
          min-width="180"
          sortable
        />
        <el-table-column
          label="Companies"
          prop="companies"
          sortable
        />
        <el-table-column
          label="Properties"
          prop="properties"
          sortable
        />
        <el-table-column
          label="Monday"
          prop="days.Mon"
          sortable
          class-name="days"
        />
        <el-table-column
          label="Tuesday"
          prop="days.Tue"
          sortable
          class-name="days"
        />
        <el-table-column
          label="Wednesday"
          prop="days.Wed"
          sortable
          class-name="days"
        />
        <el-table-column
          label="Thursday"
          prop="days.Thu"
          sortable
          class-name="days"
        />
        <el-table-column
          label="Friday"
          prop="days.Fri"
          sortable
          class-name="days"
        />
      </data-table>
    </el-row>

    <el-row class="section">
      <h2>
        Company Assignments
      </h2>
      <data-table :data="companies">
        <el-table-column
          label="Company"
          prop="human_name"
          sortable
        />
        <el-table-column
          label="Status"
          sortable
        >
          <template slot-scope="scope">
            <el-tag
              :type="getStatusTagType(scope.row.agency_status)"
              size="medium"
              disable-transitions
            >
              {{ scope.row.agency_status }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="Property Count"
          prop="properties"
          sortable
        />
        <el-table-column
          label="Assigned To"
          prop="assigned_to"
          sortable
        >
          <template slot-scope="scope">
            <el-select
              :value="scope.row.assigned_to && scope.row.assigned_to.id"
              :loading="loadingPosterAccounts"
              size="mini"
              @change="assignPoster($event, scope.row)"
            >
              <el-option
                label="Unassigned"
                :value="null"
              />
              <el-option
                v-for="poster in posterOptions"
                :key="poster.id"
                :label="poster.name"
                :value="poster.id"
              />
            </el-select>
          </template>
        </el-table-column>
      </data-table>
    </el-row>
  </div>
</template>

<script>
import { getStatusTagType } from '@/utils/rooof'

import RooofAPI from '@/services/api/rooof'
import RooofAccountAPI from '@/services/api/accounts'
import DataTable from '@/components/tables/DataTable'

export default {
  name: 'FullServiceAdministration',
  components: {
    'data-table': DataTable
  },
  data () {
    return {
      loading: false,
      loadingPosterAccounts: false,
      companies: [],
      posterOptions: [],
      posterProperties: []
    }
  },
  computed: {
    /**
     * Aggregates list of full service companies by poster
     * e.g.
     * [
     *  {
     *    poster: 'Bob Ross',
     *    companies: 3,
     *    properties: 24,
     *    days: {
     *       "Wed": 2,
     *       "Tue": 1,
     *       "": 7,
     *       "Thu": 1,
     *       "Mon": 3,
     *      "Fri": 0
    *    }
     *  },
     *  {...}
     * ]
     */
    posters () {
      const posters = {}
      for (const company of this.companies) {
        const poster = company.assigned_to
        if (poster) {
          if (posters[poster.id]) {
            posters[poster.id].companies += 1
            posters[poster.id].properties += company.properties
          } else {
            posters[poster.id] = {
              poster: poster.name,
              companies: 1,
              properties: company.properties
            }
          }
        }
      }
      for (const poster of this.posterProperties) {
        if (poster.poster.id in posters) {
          posters[poster.poster.id].days = poster.days
        }
      }
      return Object.keys(posters).map(key => posters[key])
    }
  },
  created () {
    this.fetchFullServiceCompanies()
    this.fetchPosterAccounts()
    this.getStatusTagType = getStatusTagType
  },
  methods: {
    /**
     * Fetch the full service companies and poster posting day data from the API
     */
    async fetchFullServiceCompanies () {
      try {
        this.loading = true
        this.companies = await RooofAPI.companies.agency()
        this.posterProperties = await RooofAccountAPI.caasPosters.list()
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Fetch poster accounts to select from
     */
    async fetchPosterAccounts () {
      try {
        this.loadingPosterAccounts = true
        this.posterOptions = await RooofAccountAPI.users.list({ is_poster: true })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loadingPosterAccounts = false
      }
    },
    /**
     * Assigns a poster account to a company
     *
     * @param {Number} posterId - id of poster to set to the company
     * @param {Object} company - full service company
     */
    async assignPoster (posterId, company) {
      try {
        this.loading = true
        await RooofAPI.companies.partialUpdate(company.id, {
          agency_poster: posterId
        })
        await this.fetchFullServiceCompanies()

        if (posterId) {
          const poster = this.posterOptions.find(poster => poster.id === posterId)
          const posterName = poster ? poster.name : 'Poster'
          this.$message({
            message: `${posterName} has been successfully assigned to ${company.human_name}`,
            type: 'success',
            duration: 3500
          })
        } else {
          this.$message({
            message: `Successfully unassigned poster from ${company.human_name}`,
            type: 'success',
            duration: 3500
          })
        }
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  margin: 0.5em 0 2em;

  h2 {
    margin: 0;
  }

  ::v-deep #summary td.days {
    background-color: rgba(#c0c4cc, 0.2);
  }
}
</style>
