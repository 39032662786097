<template>
  <div v-loading="loading" class="caas-queue">
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
      class="header"
    >
      <h2 class="subtitle">
        Posting Tasks for {{ today }}
      </h2>
      <h4 class="last-updated">
        Last Updated: {{ lastUpdated }}
      </h4>
    </el-row>
    <el-row
      type="flex"
      justify="space-between"
      class="controls"
    >
      <el-row>
        <el-select
          :value="companyFilter"
          size="small"
          multiple
          filterable
          clearable
          placeholder="Filter by companies"
          @change="handleFilterChange($event, 'company')"
        >
          <el-option
            v-for="company in companyFilterOptions"
            :key="company.id"
            :label="company.human_name"
            :value="company.id"
          />
        </el-select>
        <el-select
          :value="propertyFilter"
          size="small"
          multiple
          filterable
          clearable
          placeholder="Filter by properties"
          @change="handleFilterChange($event, 'property')"
        >
          <el-option
            v-for="property in propertyFilterOptions"
            :key="property.id"
            :label="property.name"
            :value="property.id"
          />
        </el-select>
        <el-select
          :value="posterFilter"
          size="small"
          multiple
          filterable
          clearable
          placeholder="Filter by posters"
          @change="handleFilterChange($event, 'poster')"
        >
          <el-option
            v-for="poster in posterFilterOptions"
            :key="poster.id"
            :label="poster.name"
            :value="poster.id"
          />
        </el-select>
        <el-select
          :value="postingDayFilter"
          size="small"
          filterable
          placeholder="Filter by posting day"
          @change="handleFilterChange($event, 'posting_day')"
        >
          <el-option
            v-for="day in postingDayFilterOptions"
            :key="day.label"
            :label="day.label"
            :value="day.value"
          />
        </el-select>
      </el-row>
      <el-button size="small" @click="fetchQueue">
        Refresh Page
      </el-button>
    </el-row>
    <el-row>
      <caas-queue-table :properties="queue" />
    </el-row>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { constants } from '@/utils/constants'

import CaasQueueTable from './_components/CaasQueueTable'
import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'CaasQueue',
  components: {
    'caas-queue-table': CaasQueueTable
  },
  data () {
    return {
      loading: false,
      lastUpdated: '',
      queue: [],
      companyFilter: [],
      companyFilterOptions: [],
      propertyFilter: [],
      propertyFilterOptions: [],
      posterFilter: [],
      posterFilterOptions: [],
      postingDayFilter: format(new Date(), 'EEE'),
      postingDayFilterOptions: []
    }
  },
  computed: {
    today () {
      return format(new Date(), 'EEEE MMMM d, yyyy')
    }
  },
  created () {
    this.fetchFilterOptions()
    this.postingDayFilterOptions = [
      { label: 'All', value: 'All' },
      ...constants.postingDayOptions
    ]

    // restore filters from route query
    const query = this.$route.query
    if (query.company) {
      this.companyFilter = Array.isArray(query.company)
        ? query.company.map(str => parseInt(str)) : [parseInt(query.company)]
    }
    if (query.property) {
      this.propertyFilter = Array.isArray(query.property)
        ? query.property.map(str => parseInt(str)) : [parseInt(query.property)]
    }
    if (query.poster_id) {
      this.posterFilter = Array.isArray(query.poster_id)
        ? query.poster_id.map(str => parseInt(str)) : [parseInt(query.poster_id)]
    }
    if (query.posting_day) {
      this.postingDayFilter = query.posting_day
    } else {
      // set default posting day to query param
      const newQuery = JSON.parse(JSON.stringify(query))
      newQuery.posting_day = this.postingDayFilter
      this.$router.replace(
        {
          path: this.$route.path,
          query: newQuery
        }
      ).catch(err => err)
    }
    this.fetchQueue()
  },
  methods: {
    /**
     * Fetches the queue from the API.
     */
    async fetchQueue () {
      try {
        this.loading = true
        const params = new URLSearchParams()
        for (const company of this.companyFilter) {
          params.append('company', company)
        }
        for (const property of this.propertyFilter) {
          params.append('property', property)
        }
        for (const poster of this.posterFilter) {
          params.append('poster_id', poster)
        }
        if (this.postingDayFilter && this.postingDayFilter !== 'All') {
          params.append('posting_day', this.postingDayFilter)
        }
        this.queue = await CraigslistAPI.service_queue.list(params)
        this.lastUpdated = format(new Date(), 'h:mm:ss a')
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Handler for when company filter select has been changed.
     *
     * @param {Array} newSelected - array of selected company ids
     */
    handleFilterChange (newSelected, filterType) {
      if (filterType === 'company') {
        this.companyFilter = newSelected
      } else if (filterType === 'property') {
        this.propertyFilter = newSelected
      } else if (filterType === 'poster') {
        this.posterFilter = newSelected
      } else if (filterType === 'posting_day') {
        this.postingDayFilter = newSelected
      }

      // persist filters via route query
      const query = {}
      if (this.companyFilter.length) {
        query.company = this.companyFilter
      }
      if (this.propertyFilter.length) {
        query.property = this.propertyFilter
      }
      if (this.posterFilter.length) {
        query.poster_id = this.posterFilter
      }
      if (this.postingDayFilter) {
        query.posting_day = this.postingDayFilter
      }
      this.$router.replace(
        {
          path: this.$route.path,
          query: query
        }
      ).catch(err => err)
    },
    /**
     * Fetches the queue without any filters
     * in order to populate the company filters with CaaS companies.
     */
    async fetchFilterOptions () {
      try {
        const queue = await CraigslistAPI.service_queue.list()
        const lookup = {}
        this.companyFilterOptions = queue.reduce((total, current) => {
          if (!lookup[current.company.id]) {
            lookup[current.company.id] = true
            total.push({ id: current.company.id, human_name: current.company.human_name })
          }
          return total
        }, []).sort((a, b) => a.human_name.localeCompare(b.human_name))

        const propertyLookup = {}
        this.propertyFilterOptions = queue.reduce((total, current) => {
          if (!propertyLookup[current.property.id]) {
            lookup[current.property.id] = true
            total.push({ id: current.property.id, name: current.property.name })
          }
          return total
        }, []).sort((a, b) => a.name.localeCompare(b.name))

        const posterLookup = {}
        this.posterFilterOptions = queue.reduce((total, current) => {
          if (current.assigned_to && !posterLookup[current.assigned_to.id]) {
            posterLookup[current.assigned_to.id] = true
            total.push({ id: current.assigned_to.id, name: current.assigned_to.name })
          }
          return total
        }, []).sort((a, b) => a.name.localeCompare(b.name))
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.caas-queue {
  .header {
    margin: 0.5em 0;
    .subtitle {
      margin: 0;
    }
    .last-updated {
      margin: 0;
    }
  }
  .controls {
    margin: 0.5em 0;
    .el-select {
      margin-right: 1em;
    }
  }
}
</style>
