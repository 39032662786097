<template>
  <el-card
    header="Posting"
    shadow="never"
  >
    <info-widget
      icon="el-icon-message"
      label="Default EH Domain"
      :value="company.default_emailhandler_domain"
    />
    <info-widget
      icon="el-icon-message"
      label="Additional EH Domain(s)"
      :collapsible="company.additional_emailhandler_domains.length > 5"
    >
      <div v-for="domain in company.additional_emailhandler_domains" :key="domain">
        {{ domain }}
      </div>
    </info-widget>
    <info-widget
      icon="el-icon-user-solid"
      label="Craigslist Accounts"
      :collapsible="company.craigslist_accounts.length > 5"
    >
      <div v-for="account in company.craigslist_accounts" :key="account">
        {{ account }}
      </div>
    </info-widget>
    <info-widget
      icon="el-icon-document-copy"
      label="Templates"
      :collapsible="templates.length > 5"
    >
      <div v-for="template in templates" :key="template">
        {{ template === 'null' ? '' : template }}
      </div>
    </info-widget>
    <info-widget
      icon="el-icon-collection"
      label="Policy"
      :value="company.policy"
      :collapsible="company.policy.length > 200"
    />
    <info-widget
      icon="el-icon-s-order"
      label="Disclaimer"
      :collapsible="company.disclaimer.length > 5"
    >
      <div v-for="disclaimer in company.disclaimer" :key="disclaimer">
        {{ disclaimer }}
      </div>
    </info-widget>
    <info-widget
      icon="el-icon-brush"
      label="Custom Fields"
      :value="company.extra"
    />
    <info-widget
      icon="el-icon-time"
      label="Price Update Hour"
      :value="company.post_update_hour ? convert24HourTo12Hour(company.post_update_hour.toString()) : '(none)'"
    />
    <info-widget
      label="Scheduled Posting in Chrome"
      :value="parseClientConfigFlag(clientConfig.enableScheduledPostingInChrome)"
    >
      <template #icon>
        <google-chrome-icon class="google-icon" />
      </template>
    </info-widget>
    <info-widget
      icon="el-icon-edit"
      label="Editing & Deleting Live Ads"
      :value="parseClientConfigFlag(clientConfig.allowEditingLiveAds)"
    />
    <info-widget
      label="Allow Self-destructing Ads"
      :value="parseClientConfigFlag(clientConfig.allowSelfDestructingAds)"
    >
      <template #icon>
        <auto-delete-icon class="auto-delete-icon" />
      </template>
    </info-widget>
    <info-widget
      icon="el-icon-edit-outline"
      label="Custom Titles in PostEngine Settings"
      :value="getConstantLabelByValue('showOrHide', clientConfig.useCustomTitles_v6)"
    />
  </el-card>
</template>

<script>
import RooofAPI from '@/services/api/rooof'
import { getConstantLabelByValue } from '@/utils/constants'

import AutoDeleteIcon from '@/assets/icons/svg/auto_delete.svg'
import GoogleChromeIcon from '@/assets/icons/svg/google_chrome.svg'
import { InfoWidget } from '@/components/widgets'
import { convert24HourTo12Hour } from '@/utils'

export default {
  components: {
    'auto-delete-icon': AutoDeleteIcon,
    'info-widget': InfoWidget,
    'google-chrome-icon': GoogleChromeIcon
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    clientConfig: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      templates: []
    }
  },
  created () {
    this.convert24HourTo12Hour = convert24HourTo12Hour
    this.getConstantLabelByValue = getConstantLabelByValue
    this.getMappingTemplates()
  },
  methods: {
    /**
     * Retrieves the template names used by mappings for the company
     */
    async getMappingTemplates () {
      try {
        this.loading = true
        const response = await RooofAPI.companies.mappingTemplates(this.$route.params.cid)
        if (response) {
          this.templates = Object.keys(response)
        }
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Returns parsed boolean to Show or Hide as string
     * @param {Boolean} value
     */
    parseCustomTitlesConfigFlag (value) {
      if (value === true) {
        return 'Show'
      } else if (value === false) {
        return 'Hide'
      }
      return value
    },
    /**
     * Returns parsed boolean to Enabled or Disabled as string
     * @param {Boolean} value
     */
    parseClientConfigFlag (value) {
      if (value === true) {
        return 'Enabled'
      } else if (value === false) {
        return 'Disabled'
      }
      return value
    }
  }
}
</script>

<style scoped>
.google-icon {
  width: 20px;
  fill: #626262;
  padding-left: 2px;
}
.auto-delete-icon {
  fill: #626262;
}
</style>
